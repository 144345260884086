import React from "react";
import { graphql, Link } from "gatsby";
import { Layout } from "/src/components/layouts/default"
import Seo from "/src/components/helpers/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Event = ({ data }) => {
  const post = data.nodeEvent;
  console.log(post);
  const langPrefix = post.langcode === 'en' ? '' : '/'+post.langcode;
  return (
    <Layout>
    <Seo title={post.title} />
    <div className="langSwitcher">
    <Link to={langPrefix + '/'}>HOME</Link>
   {post.langcode !== 'en' && data.english !== null && <Link to={data.english.path.alias}>English</Link>}
    {post.langcode !== 'pl' && data.polish !== null && <Link to={'/pl' + data.polish.path.alias}>Polish</Link>}
    {post.langcode !== 'fr' && data.french !== null && <Link to={'/fr' + data.french.path.alias}>French</Link>}
    </div>
      <h1>{post.title}</h1>
      <small>
        <em>{Date(post.created)}</em>
      </small>
      <div
        style={{ maxWidth: `900px`, marginBottom: `1.45rem`, width: `100%` }}
      >
        <GatsbyImage image={getImage(post.relationships.field_image.localFile)} alt={Event.title} />
      </div>
      {post.body !== null && <div dangerouslySetInnerHTML={{ __html: post.body.value }}></div>}
    </Layout>
  );
};

export default Event;

export const query = graphql`
  query ($drupal_internal__nid: Int, $langcode: String) {
    nodeEvent(
      drupal_internal__nid: { eq: $drupal_internal__nid }
      langcode: { eq: $langcode }
    ) {
      langcode
      title
      body {
        value
      }
      created
      path {
        alias
      }
      relationships {
        field_image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
        }
      }
    }
    english: nodeEvent(
      drupal_internal__nid: { eq: $drupal_internal__nid }
      langcode: { eq: "en" }
    ) {
      path {
        alias
      }
    }
    polish: nodeEvent(
      drupal_internal__nid: { eq: $drupal_internal__nid }
      langcode: { eq: "pl" }
    ) {
      path {
        alias
      }
    }
    french: nodeEvent(
      drupal_internal__nid: { eq: $drupal_internal__nid }
      langcode: { eq: "fr" }
    ) {
      path {
        alias
      }
    }
  }
`;
